import { isValid } from 'date-fns';

import { isFalsy } from '@/components/CF/Dashbaord/helpers';

/**
 *
 * @description Use this function only where we get date values from backend or date is created dynamically like date pickers
 */
export const getValidDate = (date?: any) => {
  const returnDate =
    !isFalsy(date) && typeof date !== `boolean` && typeof date !== `number`
      ? new Date(date)
      : new Date();
  return isValid(returnDate) ? returnDate : null;
};
