import {
  DateFormats,
  transformTimestampToReadableFormat,
} from '@/components/PartnerOnboarding/utils';

import { GBLocalStorageUtil, localStorageKeys } from './GBLocalStorageUtil';

export const MODAL_DISBALE_DATE = `2024/1/31`;

// Helper function to get the current time in hours (24-hour format)
function getCurrentHour(): number {
  return new Date().getHours();
}

// Helper function to check if a given time falls within a specified range
function isTimeInRange(
  currentHour: number,
  startHour: number,
  endHour: number,
): boolean {
  return currentHour >= startHour && currentHour < endHour;
}

type ShouldShowPopUpParams = {
  key: localStorageKeys;
  skipTimeSlot: boolean;
  maxTimesToShow?: number;
  minGapHours?: number;
  modalDisableDateTime?: string;
  maxTimesToShowPerDay?: number;
  timeSlotStart?: number[];
  timeSlotEnd?: number[];
};

// Function to check if a pop-up should be shown
export function shouldShowPopup({
  key,
  skipTimeSlot,
  maxTimesToShow,
  minGapHours,
  modalDisableDateTime,
  maxTimesToShowPerDay,
  timeSlotStart = [11, 16], // Default first and second time slot start times
  timeSlotEnd = [15, 19], // Default first and second time slot end times
}: ShouldShowPopUpParams): boolean {
  // Get the minimum gap hours, defaulting to 4 if not provided
  const minGapHoursLocal = minGapHours ?? 4;

  // Get the current date and time
  const currentDateTime = new Date();
  const currentDate = currentDateTime.toISOString().slice(0, 10);
  const currentHour = currentDateTime.getHours();

  // Get user data from localStorage

  const lastPopupTimesFromLS =
    GBLocalStorageUtil.handleReadLocalStorageData(key);
  const lastPopupTimes: string[] = Array.isArray(lastPopupTimesFromLS)
    ? lastPopupTimesFromLS
    : [];

  // If a modalDisableDateTime is provided, check if the current date and time is after the disable date and time, and return false if so
  if (modalDisableDateTime) {
    const disableDateTime = new Date(modalDisableDateTime);
    const disableDate = disableDateTime.toISOString().slice(0, 10);
    const disableHour = disableDateTime.getHours();

    if (
      currentDate > disableDate ||
      (currentDate === disableDate && currentHour >= disableHour)
    ) {
      return false;
    }
  }

  // Filter popup times to only include times from the current day
  const todayPopups = lastPopupTimes.filter((time) => {
    const popupDate = new Date(time);
    return popupDate.toISOString().slice(0, 10) === currentDate;
  });
  // Check daily popup limit (configurable)
  if (maxTimesToShowPerDay && todayPopups?.length >= maxTimesToShowPerDay) {
    return false;
  }

  // Get the total popup count
  const popupCount = lastPopupTimes?.length;

  // If the user has seen the popup the maximum number of times, return false
  if (maxTimesToShow && popupCount >= maxTimesToShow) {
    return false;
  }

  // Check if the current time is within any of the allowed time slots
  const isWithinFirstTimeSlot = isTimeInRange(
    currentHour,
    timeSlotStart[0],
    timeSlotEnd[0],
  );
  const isWithinSecondTimeSlot = isTimeInRange(
    currentHour,
    timeSlotStart[1],
    timeSlotEnd[1],
  );
  const isWithinAllowedTimeSlot =
    isWithinFirstTimeSlot || isWithinSecondTimeSlot;

  // If the current time is not within the allowed time slots and skipTimeSlot is false, return false
  if (!skipTimeSlot && !isWithinAllowedTimeSlot) {
    return false;
  }

  // Get the last popup time, if any
  const lastPopupTime =
    popupCount > 0 ? new Date(lastPopupTimes?.[popupCount - 1]) : null;

  // If there was a previous popup, check if the minimum gap time has passed
  if (lastPopupTime) {
    const hoursSinceLastPopup =
      (currentDateTime.getTime() - lastPopupTime.getTime()) / (1000 * 60 * 60);
    if (hoursSinceLastPopup < minGapHoursLocal) {
      return false;
    }
  }

  // If all checks pass, return true to show the popup
  return true;
}

export const markModalShown = (key: localStorageKeys) => {
  const lastPopupTimes: string[] =
    GBLocalStorageUtil.handleReadLocalStorageData(key) ?? [];
  lastPopupTimes.push(new Date().toISOString());
  GBLocalStorageUtil.handleSetLocalStorageData(key, lastPopupTimes);
};

export function getAdPopupImageForNov24PaymentDrive(): {
  url: string;
  className: string;
  containerClassName?: string;
} {
  const currentDateTime = new Date();
  const currentDate = currentDateTime.toISOString().slice(0, 10);

  if (currentDate === `2024-11-27`) {
    return {
      url: `https://ik.imagekit.io/onsnhxjshmp/geebee-emails/Nov%20Payment%20drive%20(2)%20(1)_1TModpYo9.png?updatedAt=1732621000849`,
      className: `max-h-[550px]`,
    };
  } else if (currentDate === `2024-11-28`) {
    return {
      url: `https://ik.imagekit.io/onsnhxjshmp/geebee-emails/2_O_6SyDO-Y.png?updatedAt=1732621279836`,
      className: `max-h-[550px]`,
    };
  } else if (currentDate === `2024-11-29`) {
    return {
      url: `https://ik.imagekit.io/onsnhxjshmp/geebee-emails/3_kPEwgi-9c.png?updatedAt=1732621279934`,
      className: `max-h-[550px]`,
    };
  }

  return {
    url: `https://ik.imagekit.io/onsnhxjshmp/geebee-emails/Nov%20Payment%20drive_fpiFZiGsLp.png?updatedAt=1732528766683`,
    className: `max-h-[500px]`,
    containerClassName: `max-w-[500px]`,
  };
}

export const getIeltsLiveDemoPopupRedirectionUrl = () => {
  const currentDate = new Date();
  const fourthDecemberDateInStrings = `4th Dec 2024`;
  const transformedDate = transformTimestampToReadableFormat(
    currentDate?.toISOString(),
    DateFormats.DATE_MONTH_YEAR,
  );

  if (transformedDate === fourthDecemberDateInStrings) {
    return `https://us02web.zoom.us/j/83445923261?pwd=6H4Dq1DFAPju2HRmbDZX5jMs5839kp.1`;
  } else {
    return `https://us02web.zoom.us/j/81492969443?pwd=wpt34T6p1D4lhzSwOQKnjD8i6X1mGV.1`;
  }
};
