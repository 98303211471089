import { isFalsy } from '@leapfinance/frontend-commons';
import { format, isValid } from 'date-fns';

export enum DateFormats {
  DATE_MONTH_YEAR_HOUR_MINUTES_A = `do MMM Y , hh:mm a`,
  DATE_MONTH_HOUR_MINUTES_A = `do MMM , hh:mm a`,
  DATE_MONTH_YEAR = `do MMM Y`,
  HOURS_MINUTES_A = `hh:mm a`,
}

export const transformTimestampToReadableFormat = (
  timeStamp: string | undefined,
  dateFormat: DateFormats,
) => {
  if (!timeStamp || isFalsy(timeStamp)) return;

  try {
    const parsedDate = new Date(timeStamp);
    if (!isValid(parsedDate)) return;
    const formattedDate = format(parsedDate, dateFormat);
    return formattedDate;
  } catch (error) {
    console.log(`Error in transforming time stamp`, timeStamp, error);
  }
};

export const CspVisaBandOptions = [
  {
    label: `Less than 10 visas`,
    value: `Less than 10 visas`,
  },
  {
    label: `10-30 visas`,
    value: `10-30 visas`,
  },
  {
    label: `30-50 visas`,
    value: `30-50 visas`,
  },
  {
    label: `50-100 visas`,
    value: `50-100 visas`,
  },
  {
    label: `100+ visas`,
    value: `100+ visas`,
  },
];

export const indexMonthMapper: Record<number, string> = {
  0: `January`,
  1: `February`,
  2: `March`,
  3: `April`,
  4: `May`,
  5: `June`,
  6: `July`,
  7: `August`,
  8: `September`,
  9: `October`,
  10: `November`,
  11: `December`,
};

export function getKeyFromObjectUsingValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function getOrdinalSuffix(num?: number) {
  if (!num) return ``;
  const j = num % 10,
    k = num % 100;
  if (j == 1 && k != 11) {
    return num + `st`;
  }
  if (j == 2 && k != 12) {
    return num + `nd`;
  }
  if (j == 3 && k != 13) {
    return num + `rd`;
  }
  return num + `th`;
}
