import { isFalsy } from '@leapfinance/frontend-commons';
import { type BaseOptionType } from '@leapfinance/frontend-commons/schema/common';
import { type OptionType } from '@leapfinance/geebee-component-library';
import { startCase, toLower } from 'lodash';
import memoize from 'lodash/memoize';

import { store } from '@/app/store';
import { ApplicationStages } from '@/components/ApplicationSection/ApplicationFieldsData';
import { SharedLists } from '@/features/sharedList/sharedListSlice';
import { definitions } from '@/types/schema';
import { BaseResponse } from '@/types/Services';

import { COMPONENT_ID_ENUM } from './constants';

/**
 *A helper function to get the option object using the option value
 * @param value Value of the option object for which you want the option
 * @param from Key of the shared list from where it should be extractred
 * @returns Option Object
 */
const getOptionValue = (value: string, from: keyof SharedLists) => {
  const sharedList = store.getState().sharedList;
  //@ts-ignore
  const filteredOption = (sharedList[from] || []).find(
    (option: { labelKey: string }) => option.labelKey === value,
  );

  return filteredOption;
};

export const memoizedGetOptionValue = memoize(getOptionValue);

/**
Transforms an array of objects to an array of options that are compatible with react-select/mui-select.
@param {Object[]} options - An array of objects containing labelKey and labelValue properties.
@returns {BaseOptionType[]} An array of options that can be passed to a react-select component.
*/
export const getLabelValueTranformOptions = (
  options: definitions[`ListDto`][],
): BaseOptionType[] => {
  return (
    options?.map((option) => ({
      label: option.labelValue ?? ``,
      value: option.labelKey,
    })) ?? []
  );
};

type AnyObject = { [key: string]: any };

/**
A function that creates a new object by merging the properties of two input objects.
If a property exists in the second object, its value is used. Otherwise, the value of the corresponding property in the first object is used.
@template T, U
@param {T} obj1 - The first input object to be merged.
@param {U} obj2 - The second input object to be merged.
@returns {T & U} A new object that merges the properties of obj1 and obj2.
*/
export const mergeObjects = (
  first: AnyObject,
  second: AnyObject,
): AnyObject => {
  const result: AnyObject = { ...first };

  Object.keys(second).forEach((key) => {
    if (second[key] !== null && second[key] !== undefined) {
      result[key] = second[key];
    } else if (!(key in result)) {
      result[key] = null;
    }
  });

  return result;
};

/**
 * Returns the label of an option based on the option value and a given array of options.
 *
 * @param {BaseOptionType|string} option - The option value or object.
 * @param {BaseOptionType[]=} options - The array of options to search for the label. Optional.
 * @returns {string} The label of the option, or an empty string if the label cannot be found.
 */
export const getOptionLabel = (
  option: BaseOptionType | string,
  options?: BaseOptionType[],
): string => {
  if (typeof option === `string`) {
    return (options ?? []).find((item) => item.value === option)?.label ?? ``;
  } else {
    return option.label ?? ``;
  }
};

export const getGraduationYear = ({
  startYear,
  count,
}: {
  startYear: number;
  count: number;
}): BaseOptionType[] => {
  return new Array(count)
    .fill(0)
    .map((_, index) => ({
      label: String(startYear + index + 1),
      value: startYear + index + 1,
    }))
    .reverse();
};

/**
 * Replaces all occurrences of hyphens with slashes in a date string.
 *
 * @param {string} dateString - The date string to be fixed.
 * @returns {string} The fixed date string with hyphens replaced by slashes.
 */
export const fixDateForAllBrowsers = (dateString: string) =>
  dateString.replace(/-/g, `/`);

/**
 * Scrolls to given id to some html element on same page and excutes some callback post that
 * @param {string} id
 * @param {function} callback
 */
export const scrollToID = (
  id: COMPONENT_ID_ENUM,
  callback?: (element?: HTMLElement | null) => void,
) => {
  const element = document.getElementById(id);
  scrollToElementWithOffset(`#${id}`, 116);
  callback?.(element);
};

/**
 * Triggers a callback function when the next state change is valid.
 *
 * @typedef {Object} NextStateChangeCallbackParams
 * @property {function} [onStatusChange] - A callback function that will be called
 * when the next state change is valid.
 * @property {definitions[`NextValidStatesDto`][]} [nextValidStates] - An array of valid
 * next states represented as `NextValidStatesDto`.
 * @property {ApplicationStages} nextStageToTrigger - The next stage to trigger, represented
 * as `ApplicationStages`.
 * @property {string} [additionalData] - Additional data (optional) that can be provided
 * to the callback function.
 * @param {NextStateChangeCallbackParams} params - The input parameters for the callback.
 */
export const triggerNextStateChangeModalCallback = ({
  nextValidStates,
  nextStageToTrigger,
  trigger,
  onStatusChange,
  failureCallback,
}: {
  onStatusChange?: (
    status: definitions[`NextValidStatesDto`],
    trigger?: `RemarkSection`,
  ) => void;
  nextValidStates?: definitions[`NextValidStatesDto`][];
  nextStageToTrigger: ApplicationStages;
  failureCallback?: () => void;
  trigger?: `RemarkSection`;
}) => {
  if (onStatusChange) {
    const nextValidStatus = nextValidStates?.find(
      (state) => state?.labelKey === nextStageToTrigger,
    );

    if (nextValidStatus?.label) {
      onStatusChange(nextValidStatus, trigger);
    } else {
      failureCallback?.();
    }
  }
};
export interface IntakeStatusInterface {
  id: number | null;
  year: number | null;
  month: string | null;
  status: string | null;
  open_date: string | null;
  deadline: string | null;
}

const currentDate = new Date();
export const CURRENT_MONTH = currentDate.getMonth();
export const CURRENT_YEAR = currentDate.getFullYear();

export const months = [
  `jan`,
  `feb`,
  `mar`,
  `apr`,
  `may`,
  `jun`,
  `jul`,
  `aug`,
  `sep`,
  `oct`,
  `nov`,
  `dec`,
];
export const getMonthNumber = (month: string) => {
  return months?.findIndex((ele: string) => ele === month?.toLowerCase());
};

export const getFilteredUpdatedIntake = (intakes: IntakeStatusInterface[]) =>
  intakes?.filter(({ year, month }: IntakeStatusInterface) => {
    if (year && month) {
      const intakeMonth = getMonthNumber(month);
      return (
        year > CURRENT_YEAR ||
        (year === CURRENT_YEAR && intakeMonth >= CURRENT_MONTH)
      );
    }
    return false;
  });

export const scrollToElementWithOffset = (id: string, headerOffset = 64) => {
  const element = document?.querySelector(id);
  const elementPosition = element?.getBoundingClientRect().top;
  const offsetPosition =
    (elementPosition || 0) + window.pageYOffset - headerOffset;

  scrollTo({
    top: offsetPosition,
    behavior: `smooth`,
  });
};

export const elementIsVisibleInViewport = (
  el: any,
  partiallyVisible = false,
) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export const getSharedListOptions = <T>(
  sharedList: T,
  optionName: keyof T,
): { label: any; value: any }[] => {
  return ((sharedList?.[optionName] ?? []) as any[]).map((option: any) => ({
    label: option.labelValue,
    value: option.labelKey,
  }));
};

export const startCaseToLower = (text: string) => {
  return startCase(toLower(text));
};

export const getBaseUrl = () => {
  // Get the protocol (e.g., 'http:', 'https:')
  const protocol = window.location.protocol;

  // Get the hostname (e.g., 'www.example.com')
  const hostname = window.location.hostname;

  // Get the port if specified (e.g., '8080', otherwise it will be an empty string)
  const port = window.location.port;

  // Construct the base URL
  let baseUrl = `${protocol}//${hostname}`;
  if (port) {
    baseUrl += `:${port}`;
  }

  // Outputs something like 'https://www.example.com' or 'https://www.example.com:8080'
  return baseUrl;
};

export const getStudentOnboardingBaseUrl = () => {
  return getBaseUrl() + `/student-registration`;
};
export const renderLabelForGBDropdowns = (
  option: string | OptionType,
  value: string | OptionType,
) => {
  if (typeof option === `string`) return false;
  if (typeof value === `string`) return option?.label === value;
  return option?.label === value?.label;
};

export const combineDateTime = (date: Date, time: Date) => {
  const combinedDateTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
  );

  return combinedDateTime.toISOString();
};
export const transformResponse = <Req extends BaseResponse<Req>, Res>(
  data: Req,
) => {
  if (!data.success) {
    return {
      data: null,
      message: data.message,
      apiError: true,
      success: false,
    } as unknown as Res;
  } else {
    return data as unknown as Res;
  }
};
/**
   * Validates if the value passed is a valid number or not. Do not do any transformations before
   * passing the value to this function.
   *
   * @param {any} value - The value to be validated, to send a raw, untransformed value
   * @returns {true | false} Returns if the value passed is a valid number or not
   
   *
   * @example
   * // Validate if router?.query?.studentId is a valid number
   * const result = isValidNumber(router?.query?.studentId); // This will return if the sent parameter is a valid number.
   */
export const isValidNumber = (value: any) => {
  if (isFalsy(value) || typeof value === `boolean`) return false;
  return !Number.isNaN(Number(value));
};
