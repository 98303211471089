import React, { Suspense } from 'react';

import { CircularProgress, Drawer, Modal } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import dynamic from 'next/dynamic';

import { RootState } from '@/app/store';
import GTEModal from '@/components/GTE/GTEModal';
import UpdateInfoModal from '@/components/Modals/UpdateInfoModal';
import LogoCropperModal from '@/components/StudentOnboarding/PublishedFormView/LogoCropperModal';
import { ModalDataType, selectModal } from '@/features/modals/modalsSlice';

import EligibleCoursesModal from './Application/EligibleCoursesModal';

import EmailConfirmationModal from './EmailConfirmationModal';
import * as Modals from './index';
import ProfileUnsavedChangesModal from './ProfileUnsavedChangesModal';

const AddToBookmark = dynamic(() => import(`./AddToBookmark`), {
  ssr: false,
});
const CreateBookmarkListModal = dynamic(
  () => import(`./CreateBookmarkListModal`),
  {
    ssr: false,
  },
);
const PaymentVerificationReminderModal = dynamic(
  () => import(`./PaymentVerificationReminderModal`),
  {
    ssr: false,
  },
);

const AddRemarkModal = dynamic(
  () => import(`@/components/Modals/AddRemarkModal`),
  {
    ssr: false,
  },
);

const NextActionsModal = dynamic(
  () => import(`@/components/ApplicationSection/NextActions/NextActionDrawer`),
  {
    ssr: false,
  },
);

const StatusChangeModals = dynamic(
  () => import(`@/components/Modals/StatusChangeModals`),
  { ssr: false },
);

const ShortlistRatingModal = dynamic(() => import(`./ShortlistRatingModal`), {
  ssr: false,
});

const VASDrawer = dynamic(
  () => import(`@/components/Modals/Drawers/VASDrawer`),
  {
    ssr: false,
  },
);

const StudentOnboardingFormPublishedFeedback = dynamic(
  () =>
    import(
      `@/components/Modals/StudentOnboarding/FormPublishedFeedback/FormPublishedFeedback`
    ),
);
const ResetPasswordSuccessModal = dynamic(
  () => import(`./RestPasswordSuccessModal`),
  { ssr: false },
);

const ResetPassword = dynamic(
  () =>
    import(
      `@leapfinance/frontend-commons/components/geebee/subAgent/ResetPassword`
    ),
  {
    ssr: false,
  },
);

const EnableMicModal = dynamic(() => import(`./EnableMicModal`), {
  ssr: false,
});

const RetakeSpeakingTestModal = dynamic(
  () => import(`./RetakeSpeakingTestModal`),
  {
    ssr: false,
  },
);

const ShortlistRecipientModal = dynamic(
  () => import(`./ShortlistRecipientModal`),
  {
    ssr: false,
  },
);

const PromoteInstituteModal = dynamic(
  () => import(`../PromoteInstituteModal`),
  {
    ssr: false,
  },
);

const TatPerceptionModal = dynamic(
  () => import(`@/components/Modals/TatPerceptionModal`),
  {
    ssr: false,
  },
);

const PartnerProfileModal = dynamic(
  () => import(`../PartnerContacts/PartnerProfileModal`),
  {
    ssr: false,
  },
);

const LoansRequest = dynamic(() => import(`./vas/Upsell/LoansRequest`), {
  ssr: false,
});

const InitStudentEngagement = dynamic(
  () => import(`@/components/Modals/InitStudentEngagement`),
  {
    ssr: false,
  },
);
const CspLeaderboard = dynamic(
  () => import(`../CspLeaderboard/CspLeaderboardModal`),
  {
    ssr: false,
  },
);

const StudentEngagementTour = dynamic(
  () => import(`@/components/Modals/StudentEngagementTour`),
  {
    ssr: false,
  },
);
const RewardHistoryModal = dynamic(
  () => import(`../CspLeaderboard/RewardHistoryModal`),
  {
    ssr: false,
  },
);

const OfferStageStudentModal = dynamic(
  () => import(`./OfferStageStudentModal`),
  {
    ssr: false,
  },
);

const VoucherStatusConfirmationDialog = dynamic(
  () => import(`./VoucherStatusConfirmationDialog`),
  {
    ssr: false,
  },
);

const VoucherGtmModal = dynamic(() => import(`./VoucherGtmModal`), {
  ssr: false,
});

export enum MODAL_TYPES {
  ADD_APPLICATION = `addApplication`,
  ADD_STUDENT = `addStudent`,
  SHORTLIST_INFORMATION_PREFERENCE = `shortlistInformationAndPrefrence`,
  PRE_VISA_INFO = `preVisaInfo`,
  MANDATORY_DOC_INFO = `mandatoryDocInfo`,
  NO_RESULTS = `NO_RESULTS`,
  ACTION_CENTER = `ACTION_CENTER`,
  FILE_VIEWER = `FILE_VIEWER`,
  STUDENT_PROFILE_ONBOARDING = `studentProfileOnBoarding`,
  ADD_BOOKMARK = `ADD_BOOKMARK`,
  CREATE_BOOKMARK_LIST_MODAL = `CREATE_BOOKMARK_LIST_MODAL`,
  PAYMENT_VERIFICATION_REMINDER = `PAYMENT_VERIFICATION_REMINDER`,
  ADD_REMARK = `ADD_REMARK`,
  NEXT_ACTIONS = `NEXT_ACTIONS`,
  STATUS_CHANGE = `statusChange`,
  EMAIL_CONFIRMATION = `EMAIL_CONFIRMATION`,
  PROFILE_UNSAVE_MODAL = `PROFILE_UNSAVE_MODAL`,
  UPDATE_INFO_MODAL = `UPDATE_INFO_MODAL`,
  SHORTLIST_RATING_MODAL = `SHORTLIST_RATING_MODAL`,
  SIMILAR_COURSE_MODAL = `SIMILAR_COURSE_MODAL`,
  STUDENT_EXAM_DETAILS_MODAL = `STUDENT_EXAM_DETAILS_MODAL`,
  UPDATE_CSP_LOGO_MODAL = `UPDATE_CSP_LOGO_MODAL`,
  ELIGIBLE_COURSES_MODAL = `ELIGIBLE_COURSES_MODAL`,
  REQUEST_VAS_CALLBACK = `REQUEST_VAS_CALLBACK`,
  NEWSLETTER_ANNOUNCEMENT = `NEWSLETTER_ANNOUNCEMENT`,
  VAS_DRAWER = `VAS_DRAWER`,
  GTE_PROCESS = `GTE_PROCESS`,
  ADD_COURSE_TO_SHORTLIST = `ADD_COURSE_TO_SHORTLIST`,
  ADD_SUB_AGENT_FORM = `ADD_SUB_AGENT_FORM`,
  DEFFERAL_HISTORY_MODAL = `DEFERRAL_HISTORY_MODAL`,
  CONFIRM_DELETE_EXISTING_MODAL = `CONFIRM_DELETE_EXISTING_MODAL`,
  STUDENT_ONBOARDING_PUBLISHED_FORM_FEEDBACK = `STUDENT_ONBOARDING_PUBLISHED_FORM_FEEDBACK`,
  STUDENT_ONBOARDING_FORM_PREVIEW = `STUDENT_ONBOARDING_FORM_PREVIEW`,
  RESET_PASSWORD_MODAL = `RESET_PASSWORD_MODAL`,
  RESET_PASSWORD_SUCCESS_MODAL = `RESET_PASSWORD_SUCCESS_MODAL`,
  GIC_MARK_SUGGESTION_MODAL = `GIC_MARK_SUGGESTION_MODAL`,
  ENABLE_MIC_MODAL = `enable_mic_modal`,
  RETAKE_SPEAKING_TEST_MODAL = `RETAKE_SPEAKING_TEST_MODAL`,
  SHORTLIST_RECIPIENT_LIST_MODAL = `SHORTLIST_RECIPIENT_LIST_MODAL`,
  LOGO_CROPPER_MODAL = `LOGO_CROPPER_MODAL`,
  TAT_PERCEPTION_MODAL = `TAT_PERCEPTION_MODAL`,
  PARTNER_PROFILE_MODAL = `PARTNER_PROFILE_MODAL`,
  LOANS_REQUEST_UPSELL = `LOANS_REQUEST_UPSELL`,
  INIT_STUDENT_ENGAGEMENT = `INIT_STUDENT_ENGAGEMENT`,
  STUDENT_ENGAGEMENT_FEATURE_TOUR = `STUDENT_ENGAGEMENT_FEATURE_TOUR`,
  CSP_LEADERBOARD_MODAL = `CSP_LEADERBOARD_MODAL`,
  REWARD_HISTORY_MODAL = `REWARD_HISTORY_MODAL`,
  OFFER_STAGE_STUDENT_MODAL = `OFFER_STAGE_STUDENT_MODAL`,
  VOUCHER_STATUS_CONFIRMATION_DIALOG = `VoucherStatusConfirmationDialog`,
  VOUCHER_GTM_MODAL = `VOUCHER_GTM_MODAL`,
}

const MODALS: Record<MODAL_TYPES, any> = {
  [MODAL_TYPES.ADD_APPLICATION]: Modals.AddApplication,
  [MODAL_TYPES.ADD_STUDENT]: Modals.AddStudentModal,
  [MODAL_TYPES.SHORTLIST_INFORMATION_PREFERENCE]:
    Modals.ShortlistInformationAndPreferenceModal,
  [MODAL_TYPES.PRE_VISA_INFO]: Modals.KeyValueDisplayModal,
  [MODAL_TYPES.MANDATORY_DOC_INFO]: Modals.RequiredDocModal,
  [MODAL_TYPES.NO_RESULTS]: Modals.NoResults,
  [MODAL_TYPES.ACTION_CENTER]: Modals.ActionCenter,
  [MODAL_TYPES.FILE_VIEWER]: Modals.FileViewer,
  [MODAL_TYPES.STUDENT_PROFILE_ONBOARDING]:
    Modals.StudentProfileOnboardingModal,
  [MODAL_TYPES.PAYMENT_VERIFICATION_REMINDER]: PaymentVerificationReminderModal,
  [MODAL_TYPES.ADD_REMARK]: AddRemarkModal,
  [MODAL_TYPES.NEXT_ACTIONS]: NextActionsModal,
  [MODAL_TYPES.STATUS_CHANGE]: StatusChangeModals,
  [MODAL_TYPES.ADD_BOOKMARK]: AddToBookmark,
  [MODAL_TYPES.CREATE_BOOKMARK_LIST_MODAL]: CreateBookmarkListModal,
  [MODAL_TYPES.EMAIL_CONFIRMATION]: EmailConfirmationModal,
  [MODAL_TYPES.PROFILE_UNSAVE_MODAL]: ProfileUnsavedChangesModal,
  [MODAL_TYPES.UPDATE_INFO_MODAL]: UpdateInfoModal,
  [MODAL_TYPES.SHORTLIST_RATING_MODAL]: ShortlistRatingModal,
  [MODAL_TYPES.SIMILAR_COURSE_MODAL]: Modals.SimilarCourseModal,
  [MODAL_TYPES.STUDENT_EXAM_DETAILS_MODAL]: Modals.StudentExamDetailsModal,
  [MODAL_TYPES.UPDATE_CSP_LOGO_MODAL]: Modals.UpdateCspLogoModal,
  [MODAL_TYPES.ELIGIBLE_COURSES_MODAL]: EligibleCoursesModal,
  [MODAL_TYPES.REQUEST_VAS_CALLBACK]: Modals.RequestVasCallbackModal,
  [MODAL_TYPES.NEWSLETTER_ANNOUNCEMENT]: PromoteInstituteModal,
  [MODAL_TYPES.VAS_DRAWER]: VASDrawer,
  [MODAL_TYPES.GTE_PROCESS]: GTEModal,
  [MODAL_TYPES.ADD_COURSE_TO_SHORTLIST]: Modals.AddCourseToShortlist,
  [MODAL_TYPES.ADD_SUB_AGENT_FORM]: Modals.AddSubAgentForm,
  [MODAL_TYPES.DEFFERAL_HISTORY_MODAL]: Modals.DeferralHistoryModal,
  [MODAL_TYPES.CONFIRM_DELETE_EXISTING_MODAL]:
    Modals.ConfirmDeleteExistingModal,
  [MODAL_TYPES.STUDENT_ONBOARDING_PUBLISHED_FORM_FEEDBACK]:
    StudentOnboardingFormPublishedFeedback,
  [MODAL_TYPES.STUDENT_ONBOARDING_FORM_PREVIEW]:
    Modals.StudentOnboardingFormPreview,
  [MODAL_TYPES.RESET_PASSWORD_MODAL]: ResetPassword,
  [MODAL_TYPES.RESET_PASSWORD_SUCCESS_MODAL]: ResetPasswordSuccessModal,
  [MODAL_TYPES.GIC_MARK_SUGGESTION_MODAL]: Modals.GICMarkSuggestionModal,
  [MODAL_TYPES.ENABLE_MIC_MODAL]: EnableMicModal,
  [MODAL_TYPES.RETAKE_SPEAKING_TEST_MODAL]: RetakeSpeakingTestModal,
  [MODAL_TYPES.SHORTLIST_RECIPIENT_LIST_MODAL]: ShortlistRecipientModal,
  [MODAL_TYPES.LOGO_CROPPER_MODAL]: LogoCropperModal,
  [MODAL_TYPES.TAT_PERCEPTION_MODAL]: TatPerceptionModal,
  [MODAL_TYPES.PARTNER_PROFILE_MODAL]: PartnerProfileModal,
  [MODAL_TYPES.LOANS_REQUEST_UPSELL]: LoansRequest,
  [MODAL_TYPES.INIT_STUDENT_ENGAGEMENT]: InitStudentEngagement,
  [MODAL_TYPES.STUDENT_ENGAGEMENT_FEATURE_TOUR]: StudentEngagementTour,
  [MODAL_TYPES.CSP_LEADERBOARD_MODAL]: CspLeaderboard,
  [MODAL_TYPES.REWARD_HISTORY_MODAL]: RewardHistoryModal,
  [MODAL_TYPES.OFFER_STAGE_STUDENT_MODAL]: OfferStageStudentModal,
  [MODAL_TYPES.VOUCHER_STATUS_CONFIRMATION_DIALOG]:
    VoucherStatusConfirmationDialog,
  [MODAL_TYPES.VOUCHER_GTM_MODAL]: VoucherGtmModal,
};

const GlobalModal = () => {
  const modalsData = useAppSelector((state: RootState) => selectModal(state));
  // const { openDrawer, Drawer, closeDrawer } = useDrawer();

  const renderComponent = (modal: ModalDataType, index: number) => {
    if (modal.modalType === null) return null;
    const ModalComponent = MODALS[modal.modalType];

    if (modal?.modalType === MODAL_TYPES.VAS_DRAWER) {
      return (
        <Drawer open={true} anchor="right">
          <Suspense
            fallback={
              <div className="flex items-center justify-center">
                <CircularProgress />
                Loading modal...
              </div>
            }
          >
            <ModalComponent {...modal.modalProps} />
          </Suspense>
        </Drawer>
      );
    }

    return (
      <Modal
        key={index}
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              <CircularProgress />
              Loading modal...
            </div>
          }
        >
          <ModalComponent {...modal.modalProps} />
        </Suspense>
      </Modal>
    );
  };

  return (
    <>
      {modalsData?.map((modal, index) =>
        modal.modalType ? renderComponent(modal, index) : null,
      )}
    </>
  );
};

export default GlobalModal;
